/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountLayout from '../account/AccountLayout';
import Button from '../components/Button';
import Link from '../components/Link';
import { PaymentSelection } from '../components/Payment';
import Spinner from '../components/Spinner';
import AddDiscountCode from '../parcelFlow/AddDiscountCode';
import DiscountCodeRow from '../parcelFlow/DiscountCodeRow';
import { PickupForm } from '../parcelFlow/PickupPoint';
import {
  PAGE,
  startTransferFlow,
  selectDestination,
  setTransferPrice,
  goBack,
  goNext,
  saveDiscount,
  createOrder,
  setRedirect,
} from '../state/transfer';
import { isBrowser } from '../utils';
import * as analytics from '../utils/analytics';
import * as api from '../utils/api';
import { useTranslate } from '../utils/getLanguage';
import { formatOffice, formatPrice } from '../utils/formatters';
import { setOrderId } from '../utils/order';
import { capitalize } from '../utils/string';
import { locNavigate } from '../state/session';
import { reloadShipments } from '../state/shipments';

const SelectDestination = () => {
  const translate = useTranslate();
  const { shipmentNumber, sender, currentDestination, freeTransfer } = useSelector(state => state.transfer);
  const pickupPoint = useSelector(state => state.transfer.destination) || {};
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => window.history.back(), []);
  const onNextClick = useCallback(() => dispatch(goNext()), [dispatch]);
  const onSelect = useCallback(
    async p => {
      dispatch(selectDestination(p));
      const pricing = await api.getTransferPrice(shipmentNumber, p.officeCode);
      dispatch(setTransferPrice(pricing));
    },
    [dispatch, shipmentNumber]
  );

  const props = {
    isTransfer: true,
    exclude: [currentDestination],
    pickupPoint,
    country: 'FI',
    onBackClick,
    onNextClick,
    onSelect,
  };

  return (
    <Box>
      <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary', alignSelf: 'flex-start' }}>
        {translate('backButton')}
      </Button>
      <div sx={{ flex: 1 }} />
      <Styled.h1 sx={{ color: 'secondary', mb: 3 }}>{translate('transfer.title')}</Styled.h1>
      <p sx={{ mb: 0 }}>{translate('transfer.description', { shipmentNumber, sender })}</p>
      <p sx={{ mb: 0 }}>{translate('transfer.choose')}</p>
      {!freeTransfer ? <p sx={{ mb: 3 }}>{translate('transfer.noAutomats')}</p> : null}

      <PickupForm {...props} />
    </Box>
  );
};

const ConfirmOrder = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { shipmentNumber, sender, destination, discount, pricing, redirect } = useSelector(state => state.transfer);
  const servicePrice = pricing?.price || 0.0;
  const totalPrice = Math.max(0.0, servicePrice - (discount?.amount || 0.0));
  const discountAmount = servicePrice - totalPrice;

  useEffect(() => {
    if (redirect) {
      dispatch(locNavigate(redirect));
    }
  }, [dispatch, redirect]);

  const checkDiscount = useCallback(
    async code => {
      if ((code || '').length < 5) {
        return 'tooShort';
      }
      const serviceCode = 'TRANSFER';
      const response = await api.checkDiscountCode(code, serviceCode);
      if (typeof response === 'string') {
        return response;
      }
      dispatch(saveDiscount(code, response));
    },
    [dispatch]
  );
  const removeDiscount = useCallback(() => {
    dispatch(saveDiscount());
  }, [dispatch]);

  const onContinue = useCallback(() => {
    setIsSubmitting(true);
    if (totalPrice > 0) {
      dispatch(goNext());
    } else {
      dispatch(createOrder({ paymentMethod: 'FREE' }));
    }
  }, [totalPrice, dispatch, isSubmitting]);

  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Box>
      <Box sx={{ maxWidth: 640 }}>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('newDelivery.back')}
        </Button>

        <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('transfer.confirmTitle')}</Styled.h1>
        <p sx={{ mb: 0 }}>{translate('transfer.what', { shipmentNumber, sender })}</p>
        <Flex sx={{ mt: 3, alignItems: 'flex-start' }}>
          <span>{translate('transfer.destination')}</span>
          <div sx={{ flex: 1, ml: 2 }}>
            <div sx={{ fontWeight: 'medium' }}>{capitalize(destination.officeName)}</div>
            <div>
              {capitalize(destination.officeStreetAddress)}, {destination.officePostalCode}{' '}
              {capitalize(destination.officeCity)}
            </div>
          </div>
        </Flex>

        {discount ? (
          <DiscountCodeRow discount={discount} amount={discountAmount} removeDiscount={removeDiscount} />
        ) : (
          servicePrice > 0.0 && <AddDiscountCode checkDiscount={checkDiscount} />
        )}
        <Box sx={{ mt: 2, mb: 3, fontWeight: 'medium', fontSize: 3 }}>
          <span sx={{ mr: 2 }}>{translate('transfer.price')}</span>
          {!pricing ? <Spinner /> : <span sx={{ color: 'primary' }}>{formatPrice(totalPrice)}</span>}
        </Box>
      </Box>
      {servicePrice > 0 ? (
        <p sx={{ mb: 3 }}>
          {translate('transfer.authorisation', { shipmentNumber, destination: formatOffice(destination) })}
        </p>
      ) : null}

      <Button
        sx={{ mt: 2 }}
        onClick={onContinue}
        disabled={(!servicePrice && servicePrice != 0.0) || !pricing || isSubmitting}
      >
        {totalPrice > 0 ? translate('transfer.pay') : translate('transfer.confirm')}
      </Button>
    </Box>
  );
};

const Payment = () => {
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const [isProcessing, setProcessing] = useState(false);

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      const order = await dispatch(createOrder(paymentOptions));
      if (order && order.redirectUrl) {
        setOrderId(order.orderId, order.transactionId);
        isBrowser && window.location.assign(order.redirectUrl);
        return;
      }
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={onBackClick} />;
};

const TransferThankYou = ({ state }) => {
  const translate = useTranslate();
  const { order } = state || {};
  const { shipmentNumber: newShipmentNumber, shipment, transfer } = order;
  const { shipmentNumber, senderName: sender } = shipment;
  const destination = formatOffice(transfer.destination);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRedirect(null));
    dispatch(reloadShipments());
  }, [dispatch]);

  return (
    <div>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('transfer.ready')}</Styled.h1>
      <p sx={{ my: 2 }}>{translate('transfer.transferTo', { shipmentNumber, sender, destination })}</p>
      {+shipment?.shipmentStatus > 19 ? (
        <>
          <p sx={{ my: 2 }}>{translate('transfer.newShipmentNumber', { newShipmentNumber })}</p>
          <p sx={{ my: 2 }}>{translate('transfer.notification')}</p>
        </>
      ) : null}

      <p>
        <Link to="/my-pages/incoming-parcels">{translate('transfer.return')}</Link>
      </p>
    </div>
  );
};

export default ({ pageContext, location: { state } = {} }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  analytics.usePageCategory('paketit');

  const stateKeys = Object.keys(state || {});
  const hasState = stateKeys.includes('shipmentNumber') || stateKeys.includes('order');

  if (!hasState) {
    try {
      state = isBrowser && JSON.parse(window.sessionStorage.getItem('transfer'));
    } catch (err) {
      // no-op
    }
  } else if (!state.saved) {
    // remember shipment if user refresh browser
    isBrowser && window.sessionStorage.setItem('transfer', JSON.stringify(state));
    state.saved = true;
  }

  const shipmentNumber = useSelector(state => state.transfer.shipmentNumber);
  const page = useSelector(state => state.transfer.page);
  useEffect(() => {
    if (state.shipmentNumber && shipmentNumber !== state.shipmentNumber) {
      dispatch(startTransferFlow(state));
    }
  }, [shipmentNumber, dispatch, state]);

  return (
    <AccountLayout
      title={translate('transfer.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
    >
      <Container variant="parcel" sx={{ my: 4 }}>
        {state && state.order ? (
          <TransferThankYou state={state} />
        ) : (
          (() => {
            switch (page) {
              case PAGE.SELECT_DESTINATION:
                return <SelectDestination />;
              case PAGE.CONFIRM_ORDER:
                return <ConfirmOrder />;
              case PAGE.PAYMENT:
                return <Payment />;
              default:
                return <h1>Virhetilanne</h1>;
            }
          })()
        )}
      </Container>
    </AccountLayout>
  );
};
